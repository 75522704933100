import React, { useState, useEffect, useCallback, useRef } from "react";

const usePolling = (apiFunction, isSuccess) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const savedCallback = useRef();
  const timerRef = useRef();
  const fetchData = useCallback(async () => {
    try {
      const result = await apiFunction();
      console.log(isSuccess(result));
      if (isSuccess(result)) {
        setData(result);
        setLoading(false);
      } else {
        // 如果没有获取到结果，继续设置下一个轮询
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(fetchData, 3000);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
      setLoading(false); // 发生错误时停止轮询
    }
  }, [apiFunction]);

  useEffect(() => {
    if (loading && !savedCallback.current) {
      savedCallback.current = true;
      fetchData();
    }
    // 因为我们在fetchData中控制轮询，所以这里不需要清理函数
  }, [fetchData, loading]);

  const resetPolling = () => {
    savedCallback.current = false;

    setLoading(true);
    setData(null);
  };

  const stopPolling = () => {
    clearTimeout(timerRef.current);
    setLoading(false);
  };
  const pollLoading = loading;
  const pollData = data;
  return { pollData, pollLoading, resetPolling, stopPolling };
};

export default usePolling;
