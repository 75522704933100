/* eslint-disable jsx-a11y/alt-text */

import React, { Component } from "react";

import styles from "./assets/index.module.css";

const DDS_lanhupage_0 = (props) => {
  const {
    handleCloseDownload,
    setCurrentStep,
    productDetailData,
    payResult = {},
  } = props;
  return (
    <div className={`${styles["group_1"]} flex-col`}>
      <div className={`${styles["block_1"]} flex-row`}>
        <span className={`${styles["text_1"]}`}>{payResult.name}</span>
        <img
          onClick={() => {
            handleCloseDownload();
          }}
          className={`${styles["thumbnail_1"]}`}
          src={require("./assets/img/SketchPngc1ab939d22f63bf033e4627b59034e85668393de825cfb125328b101c6a6ab48.png")}
        />
      </div>
      <div className={`${styles["block_2"]} flex-col`}>
        <div className={`${styles["image-text_1"]} flex-col justify-between`}>
          <div className={`${styles["image-wrapper_1"]} flex-col`}>
            <img
              className={`${styles["thumbnail_2"]}`}
              src={require("./assets/img/SketchPng67ed2312c6a73f72d78669288d3d953b7227e8d6b2f8cd45689ef009243edcac.png")}
            />
            <img
              className={`${styles["thumbnail_3"]}`}
              src={require("./assets/img/SketchPngb5ac10d466a242b604e96c347280d7d16c9a394323145f5b3eb9c37b4d142afe.png")}
            />
          </div>
          <div className={`${styles["text-group_1"]} flex-col justify-between`}>
            <span className={`${styles["text_2"]}`}>支付完成</span>
            <span className={`${styles["text_3"]}`}>
              点击进入下方安装指引即可安装
            </span>
          </div>
        </div>
        <img
          className={`${styles["thumbnail_4"]}`}
          src={require("./assets/img/SketchPng25f97febb3900629af622be28201157ce98dc84af8d59a6737d4547b9cea2aeb.png")}
        />
        <div className={`${styles["block_3"]} flex-col`}>
          <span className={`${styles["text_4"]}`}>已成功购买以下商品</span>
          <div className={`${styles["block_4"]} flex-row`}>
            <div className={`${styles["box_2"]} flex-col`}>
              <div className={`${styles["group_2"]} flex-col`}>
                <div className={`${styles["box_3"]} flex-col`}>
                  <div className={`${styles["section_1"]} flex-col`}>
                    <img
                      className={`${styles["section_1_img"]}`}
                      src={payResult.iconUrl}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`${styles["text-wrapper_1"]} flex-col justify-between`}
            >
              <span className={`${styles["text_5"]} `}>{payResult.name}</span>
              <span
                title={payResult.brief}
                className={`${styles["paragraph_1"]} text-two-ellipsis`}
              >
                {payResult.brief}
              </span>
            </div>
          </div>
        </div>
        <div
          className={`${styles["text-wrapper_2"]} flex-col`}
          onClick={() => {
            window.open(payResult?.mainBtn?.url);
          }}
        >
          <span className={`${styles["text_6"]}`}>
            {payResult?.mainBtn?.text}
          </span>
        </div>
        <span
          className={`${styles["text_7"]}`}
          onClick={() => {
            window.open(payResult?.subBtn?.url);
          }}
        >
          {payResult?.subBtn?.text}
        </span>
      </div>
    </div>
  );
};
export default DDS_lanhupage_0;
