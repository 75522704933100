/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { Input } from "antd";
import styles from "./assets/index.module.css";

const DDS_lanhupage_0 = (props) => {
  const {
    handleCloseDownload,
    setCurrentStep,
    productDetailData,
    userData,
    setUserData,
  } = props;
  const [phone, setPhpone] = useState(userData.phone || "");
  const [productIndex, setProductIndex] = useState(userData.productIndex || 0);
  const [toastVisible, setToastVisible] = useState(false);
  const [toastJumpVisible, setToastJumpVisible] = useState(false);
  let detail =
    (productDetailData?.sku.length && productDetailData?.sku[productIndex]) ||
    {};

  console.log(detail);
  const showToast = () => {
    setToastVisible(true);
  };
  useEffect(() => {
    if (toastVisible) {
      const timer = setTimeout(() => {
        setToastVisible(false);
      }, 3000); // 3秒后自动关闭
      return () => clearTimeout(timer); // 清除定时器
    }
  }, [toastVisible]);

  const handlePhoneChange = (e) => {
    const { value } = e.target;
    const reg = /^[0-9\b]+$/; // 只允许数字
    if (value === "" || reg.test(value)) {
      setPhpone(value);
      setToastVisible(false);
    }
  };
  function isValidChinesePhoneNumber(phoneNumber) {
    const regex = /^1[3-9]\d{9}$/;
    return regex.test(phoneNumber);
  }

  const handleSubmit = () => {
    if (phone.length === 11 && isValidChinesePhoneNumber(phone)) {
      // 处理提交逻辑，例如打印手机号或发送到服务器
      setToastJumpVisible(true);
      setUserData({
        productIndex,
        phone,
      });
      setTimeout(() => {
        setToastJumpVisible(false);
        console.log("Submitted Phone Number:", phone);
        setCurrentStep(2);
      }, 3000);
    } else {
      showToast();
    }
  };

  return (
    <>
      <div
        style={{
          height: "420px",
          top: productDetailData?.noticeContent ? "300px" : "250px",
        }}
        className={`${styles["group_4"]} flex-row justify-between`}
      >
        <div className={`${styles["box_2"]} flex-col justify-end`}>
          {productDetailData?.noticeContent && (
            <div className={`${styles["text-wrapper_1"]} flex-col`}>
              <span
                className={`${styles["text_1"]}`}
                onClick={() => {
                  window.open(productDetailData?.noticeUrl);
                }}
              >
                {productDetailData?.noticeContent}
              </span>
            </div>
          )}

          <div className={`${styles["box_3"]} flex-row`}>
            <div className={`${styles["group_2"]} flex-col`}>
              <img className={`${styles["img_size_w100"]}`} src={productDetailData?.iconUrl} />
              {/* <div className={`${styles["group_3"]} flex-col`}>
                <div className={`${styles["section_1"]} flex-col`} />
                <div className={`${styles["section_2"]} flex-col`} />
              </div> */}
            </div>
            <div
              className={`${styles["text-wrapper_9"]} flex-col justify-between`}
            >
              <span className={`${styles["text_2"]}`}>
                {productDetailData?.name}
              </span>
              <span className={`${styles["text_3"]}`}>
                {productDetailData?.brief}
              </span>
            </div>
          </div>
          <div className={`${styles["group_5"]} flex-row justify-between`}>
            <div className={`${styles["block_1"]} flex-col`} />
            <span className={`${styles["text_4"]}`}>选择版本</span>
          </div>
          <div className={`${styles["list_2"]} flex-row`}>
            {Array.isArray(productDetailData?.sku) &&
              productDetailData?.sku.map((item, index) => (
                <div
                  className={`${
                    productIndex === index
                      ? styles["list-items_active"]
                      : styles["list-items_normal"]
                  } flex-row`}
                  onClick={() => {
                    setProductIndex(index);
                  }}
                  key={index}
                >
                  <span className={`${styles["text_5-1"]}`}>{item.name}</span>
                </div>
              ))}
          </div>
          <div className={`${styles["group_6"]} flex-row justify-between`}>
            <div className={`${styles["block_2"]} flex-col`} />
            <span className={`${styles["text_6"]}`}>请输入手机号</span>
          </div>
          <div className={`${styles["group_7"]} flex-row justify-between`}>
            {/* <input
              className={`${styles["text-wrapper_3"]} flex-col`}
              placeholder="请认真填写手机号，手机号将作为激活凭证"
            /> */}
            <Input
              value={phone}
              maxLength={11}
              onChange={handlePhoneChange}
              className={`${styles["inputArea"]}`}
              placeholder="请认真填写手机号，手机号将作为激活凭证"
            />
            <>
              <div className={`${styles["text-price"]}`}>
                <span className={`${styles["text-price_label"]}`}>
                  应付金额：
                </span>
                <span className={`${styles["text-price_count"]}`}>
                  ¥{detail.price}
                </span>
              </div>
              <div
                className={`${styles["text-wrapper_4"]} flex-col`}
                onClick={() => {
                  handleSubmit();
                }}
              >
                <span className={`${styles["text_8"]}`}>去付款</span>
              </div>
            </>
          </div>
          <div className={`${styles["text-wrapper_5"]} flex-col`}>
            <span className={`${styles["text_9"]}`}>
              下单后立即发送，提供激活指引快速开始
            </span>
          </div>
        </div>
        {toastVisible && (
          <div className={`${styles["section_5"]} flex-col justify-between`}>
            <div className={`${styles["text-wrapper_10"]} flex-col`}>
              <span className={`${styles["text_17"]}`}>请输入正确手机号</span>
            </div>
          </div>
        )}

        {toastJumpVisible && (
          <div className={`${styles["section_51"]} flex-col justify-between`}>
            <div className={`${styles["text-wrapper_11"]} flex-col`}>
              <span className={`${styles["paragraph_1"]}`}>
                正在匹配软件...请耐心等待
                <br />
                即将跳转至支付页
              </span>
            </div>
          </div>
        )}
      </div>
      <div className={`${styles["group_8"]} flex-row`}>
        <div className={`${styles["box_7"]} flex-col`}>
          <div className={`${styles["section_3"]} flex-row`}>
            <span className={`${styles["text_10"]}`}>
              {productDetailData?.name}
            </span>
            <div
              className={`${styles["image-wrapper_2"]} flex-col justify-center`}
            >
              <img
                onClick={() => {
                  handleCloseDownload();
                }}
                className={`${styles["thumbnail_2"]}`}
                src={require("./assets/img/SketchPng59735bfd45cd60df3a2582e54f5ea39424997fdd0d0c6309e272a20fce4002a9.png")}
              />
            </div>
          </div>
          <div className={`${styles["group_9"]} flex-row`}>
            <div className={`${styles["text-wrapper_6"]} flex-col`}>
              <span className={`${styles["text_11"]}`}>1</span>
            </div>
            <span className={`${styles["text_12"]}`}>选择软件</span>
            <img
              className={`${styles["image_1"]}`}
              src={require("./assets/img/SketchPng5c1cc11331c804142086f40d7ebfa00f4ce20bffb56ce5b73f603a8df3b7c0e9.png")}
            />
            <div className={`${styles["text-wrapper_7"]} flex-col`}>
              <span className={`${styles["text_13"]}`}>2</span>
            </div>
            <span className={`${styles["text_14"]}`}>激活软件</span>
            <img
              className={`${styles["image_2"]}`}
              src={require("./assets/img/SketchPng5c1cc11331c804142086f40d7ebfa00f4ce20bffb56ce5b73f603a8df3b7c0e9.png")}
            />
            <div className={`${styles["text-wrapper_8"]} flex-col`}>
              <span className={`${styles["text_15"]}`}>3</span>
            </div>
            <span className={`${styles["text_16"]}`}>安装软件</span>
          </div>
        </div>
      </div>

      {/* <Toast
        message="请输入11位有效手机号码。"
        isVisible={toastVisible}
        onClose={hideToast}
      /> */}
    </>
  );
};
export default DDS_lanhupage_0;
