/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useRef, useEffect } from "react";
import styles from "./assets/index.module.css";
import { QRCode, Space } from "antd";
import { queryPayInfo, queryPayResult } from "../../services/api.js";
import usePolling from "../../hooks/poll.jsx";

const DDS_lanhupage_0 = (props) => {
  const {
    handleCloseDownload,
    setCurrentStep,
    productDetailData,
    userData,
    setPayResult,
    userId,
  } = props;
  const [payType, setPayType] = useState("aLi");
  const [payData, setPayData] = useState({});
  const [loading, setLoading] = useState(false);
  const [expired, setExpired] = useState(false);
  const timerRef = useRef();
  let detail =
    (productDetailData?.sku.length &&
      productDetailData?.sku[userData.productIndex]) ||
    {};

  const apiFunction = async () => {
    // 这里替换为实际的API调用
    return await queryPayResult(payData.orderNo);
  };
  const isSuccess = (data) => {
    // 这里替换为实际的API调用
    return data?.data?.payStatus === 1;
  };

  const { pollData, pollLoading, resetPolling, stopPolling } = usePolling(
    apiFunction,
    isSuccess
  );
  const getPayInfo = async () => {
    setExpired(false);

    setLoading(true);

    let res = await queryPayInfo({
      code: detail.code,
      payType: payType === "weChat" ? 1 : 2,
      mobile: userData.phone,
    });
    setTimeout(() => {
      setLoading(false);
    }, 600);

    if (res.code === 0) {
      setPayData(res.data);
      // QRCode.toDataURL(res.data.payUrl, function (err, url) {
      //   if (err) throw err;

      //   console.log(url); // 这个URL是一个表示二维码的data URI
      // });
      clearTimeout(timerRef.current);
      resetPolling();
      timerRef.current = setTimeout(() => {
        setExpired(true);
        stopPolling();
      }, res.data.expireMillisecond);
    }
  };

  useEffect(() => {
    getPayInfo();
  }, [payType]);

  useEffect(() => {
    if (
      !pollLoading &&
      pollData?.code === 0 &&
      pollData?.data?.payStatus === 1
    ) {
      setPayResult(pollData.data);
      setCurrentStep(3);
    }
  }, [pollLoading]);
  return (
    <div className={`${styles["box_2"]} flex-col`}>
      <div className={`${styles["section_1"]} flex-row`}>
        <span className={`${styles["text_1"]}`}>{productDetailData.name}</span>
        <img
          onClick={() => {
            handleCloseDownload();
          }}
          className={`${styles["thumbnail_1"]}`}
          src={require("./assets/img/SketchPngc1ab939d22f63bf033e4627b59034e85668393de825cfb125328b101c6a6ab48.png")}
        />
      </div>
      <div className={`${styles["section_2"]} flex-row`}>
        <div className={`${styles["group_1"]} flex-col`}>
          <div className={`${styles["text-wrapper_1"]}`}>
            <span className={`${styles["text_2"]}`}>商品名称&nbsp;</span>
            <span className={`${styles["text_3"]}`}>
              {productDetailData.name}
            </span>
          </div>
          <div className={`${styles["text-wrapper_2"]}`}>
            <span className={`${styles["text_4"]}`}>版本类型&nbsp;</span>
            <span className={`${styles["text_5"]}`}>{detail.name}</span>
          </div>
          <div className={`${styles["text-wrapper_3"]} flex-row`}>
            <span className={`${styles["text_6"]}`}>应付金额&nbsp;&nbsp;</span>
            <span className={`${styles["text_7"]}`}>￥{detail.price}</span>
          </div>
          <div className={`${styles["text-wrapper_4"]}`}>
            <span className={`${styles["text_8"]}`}>手机号码&nbsp;</span>
            <span className={`${styles["text_9"]}`}>{userData.phone}</span>
          </div>
          <div className={`${styles["text-wrapper_5"]}`}>
            <span className={`${styles["text_10"]}`}>安装说明&nbsp;</span>
            <span className={`${styles["text_11"]}`}>
              支付成功后，一键下载并安装，永久使用
            </span>
          </div>
          <div
            className={`${styles["box_3"]} flex-row`}
            onClick={() => {
              setPayType("aLi");
            }}
          >
            <span className={`${styles["text_12"]}`}>支付方式&nbsp;&nbsp;</span>
            <div
              className={`${styles["group_2"]} flex-row ${
                payType === "aLi" && styles["payActive"]
              }`}
            >
              <div className={`${styles["image-text_6"]} flex-row justify-between`}>
                <img
                  className={`${styles["thumbnail_5"]}`}
                  src={require("./assets/img/SketchPngc3a740da55431c0911dfd9bdd71a396af4d8abed1481fc4bea28ec743d39af98.png")}
                />

                <span className={`${styles["text-group_5"]}`}>支付宝</span>
                <div className={`${styles["text-wrapper_6"]} flex-col`}>
                  <span className={`${styles["text_13"]}`}>推荐</span>
                </div>
              </div>
            </div>
            {/* <div className={`${styles["image-wrapper_1"]} flex-col`}>
              <img
                className={`${styles["thumbnail_2"]}`}
                src={require("./assets/img/SketchPng9a98e8602d51bccbc675ef5914542a0d10c75d2c0afdbbf8eb95236654614ab5.png")}
              />
            </div> */}
          </div>
          <div className={`${styles["image-text_2"]} flex-row justify-between`}>
            <img
              className={`${styles["thumbnail_3"]}`}
              src={require("./assets/img/SketchPngb1d88521364129285ad27a8730b9dc93d59b8fdb8f3af7c11f48c084829091fe.png")}
            />
            <span className={`${styles["text-group_2"]}`}>
              安装程序将在付款后提供
            </span>
          </div>
        </div>
        <img
          className={`${styles["image_2"]}`}
          src={require("./assets/img/SketchPng743c415461254ade1533100caca35cd1579d6fb968332797c900891bf47894a6.png")}
        />
        <div className={`${styles["group_3"]} flex-col`}>
          <div className={`${styles["box_4"]} flex-row`}>
            <div
              className={`${styles["image-text_3"]} flex-col justify-between`}
            >
              <div className={`${styles["group_4"]} flex-col`}>
                {/* <img
                  className={`${styles["label_1"]}`}
                  src={require("./assets/img/SketchPng54d9c51e9a12110bc93a45b5a4085adedce57873a7a4c4d0a913f368db2cdcba.png")}
                /> */}
                {payData.payUrl && (
                  <QRCode
                    status={
                      loading ? "loading" : expired ? "expired" : "active"
                    }
                    value={payData.payUrl || "-"}
                    type="canvas"
                    onRefresh={getPayInfo}
                    className={`${styles["img_size_w100"]}`}
                    style={{display: 'block', width: "100%", height: "100%"}}
                  />
                )}

                {/* <div className={`${styles["group_5"]} flex-row`}>
                  <div
                    className={`${styles["image-text_4"]} flex-col justify-between`}
                  >
                    <div className={`${styles["image-wrapper_2"]} flex-col`}>
                      <img
                        className={`${styles["thumbnail_4"]}`}
                        src={require("./assets/img/SketchPngc6fff95a4e717fa686ca7771a19bf2116b7529a83b8a57fa0f9c8da58e01381b.png")}
                      />
                    </div>
                    <span className={`${styles["text-group_3"]}`}>
                      刷新二维码
                    </span>
                  </div>
                  <div
                    className={`${styles["image-text_5"]} flex-col justify-between`}
                  >
                    <div className={`${styles["image-wrapper_2"]} flex-col`}>
                      <img
                        className={`${styles["thumbnail_4"]}`}
                        src={require("./assets/img/SketchPngc6fff95a4e717fa686ca7771a19bf2116b7529a83b8a57fa0f9c8da58e01381b.png")}
                      />
                    </div>
                    <span className={`${styles["text-group_3"]}`}>
                      刷新二维码
                    </span>
                  </div>
                </div> */}
              </div>
              <div className={`${styles["text-group_4"]}`}>
                <span className={`${styles["text_14"]}`}>请使用</span>
                <span
                  className={`${
                    payType === "weChat"
                      ? styles["text_151"]
                      : styles["text_15"]
                  }`}
                >
                  {payType === "weChat" ? "微信" : "支付宝"}
                </span>
                <span className={`${styles["text_16"]}`}>进行扫码付款</span>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${styles["group_6"]} flex-row ${
            payType === "weChat" && styles["payActive"]
          }`}
          onClick={()=>setPayType("weChat")}
        >
          <div
            className={`${styles["image-text_1"]} flex-row justify-between`}
          >
            <img
              className={`${styles["image_1"]}`}
              src={require("./assets/img/SketchPngec51888b9a6f0bf11d3e79206b3308be39c3059c541cf64ac0c1681f35cd7b34.png")}
            />
            <span className={`${styles["text-group_1"]}`}>微信</span>
          </div>
        </div>
      </div>
      {/* <div className={`${styles["section_3"]} flex-row justify-end`}>
        <div className={`${styles["group_7"]} flex-row`}>
          <img
            className={`${styles["image_3"]}`}
            src={require("./assets/img/SketchPngec51888b9a6f0bf11d3e79206b3308be39c3059c541cf64ac0c1681f35cd7b34.png")}
          />
          <div className={`${styles["image-text_7"]} flex-row justify-between`}>
            <span className={`${styles["text-group_6"]}`}>微信</span>
            <div className={`${styles["image-wrapper_3"]} flex-col`}>
              <img
                className={`${styles["thumbnail_6"]}`}
                src={require("./assets/img/SketchPng9a98e8602d51bccbc675ef5914542a0d10c75d2c0afdbbf8eb95236654614ab5.png")}
              />
            </div>
          </div>
        </div>
        <div className={`${styles["group_8"]} flex-row`}>
          <div className={`${styles["image-text_8"]} flex-row justify-between`}>
            <img
              className={`${styles["thumbnail_7"]}`}
              src={require("./assets/img/SketchPngc3a740da55431c0911dfd9bdd71a396af4d8abed1481fc4bea28ec743d39af98.png")}
            />
            <span className={`${styles["text-group_7"]}`}>支付宝</span>
          </div>
        </div>
        <img
          className={`${styles["image_4"]}`}
          src={require("./assets/img/SketchPngc0f31d2808f1020d48aa3279fcdf7af968127279501ae5d8794bbb1cfd876b42.png")}
        />
      </div> */}
      {/* <div className={`${styles["section_4"]} flex-row`}>
        <div className={`${styles["image-text_9"]} flex-col justify-between`}>
          <img
            className={`${styles["label_2"]}`}
            src={require("./assets/img/SketchPng31aa773c8932d4fef7b89820867d6f8f1ca32122a29f182c489bbdda351d9749.png")}
          />
          <span className={`${styles["text-group_8"]}`}>获取二维码</span>
        </div>
      </div> */}
    </div>
  );
};
export default DDS_lanhupage_0;
