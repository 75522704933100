/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import Step1 from "../step1/index.jsx";
import Step2 from "../step2/index.jsx";
import Step3 from "../step3/index.jsx";
import styles from "./assets/index.module.css";
import { queryIndexInfo, queryProductDetail } from "../../services/api.js";
const routeList = [
  {
    name: "首页",
    key: "home",
  },
  {
    name: "关于我们",
    key: "about",
  },
  {
    name: "联系方式",
    key: "contact",
  },
];
const Home = ({ match }) => {
  const { userId } = match.params;
  const [visiable, setVisiable] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [userData, setUserData] = useState({});
  const [route, setRoute] = useState("home");
  const [rawData, setRawData] = useState({});
  const [productDetailData, setProductDetailData] = useState({});
  const [payResult, setPayResult] = useState({});
  const handleDownload = () => {
    window.scrollTo(0, 0);

    setVisiable(true);
    document.querySelector("html").style.paddingRight = "14px";

    document.querySelector("html").style.overflowY = `hidden `;
  };
  const handleCloseDownload = () => {
    setVisiable(false);
    document.querySelector("html").style.paddingRight = "0px";

    document.querySelector("html").style.overflowY = `auto `;
  };

  const getData = async () => {
    let res = await queryIndexInfo(userId);
    if (res.code === 0) {
      setRawData(res.data);
    }
    let resProductDetailData = await queryProductDetail(userId);
    if (resProductDetailData.code === 0) {
      setProductDetailData(resProductDetailData.data);
      document.title = res.data?.siteInfo?.title;
      changeFavicon(res.data?.siteInfo?.iconUrl);
    }
  };
  function changeFavicon(src) {
    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = src;
  }

  useEffect(() => {
    getData();
  }, []);
  return (
    <div className={`${styles.page} flex-col`}>
      <div className={`${styles["section_1"]} flex-col`}>
        <div className={`${styles["box_1"]} flex-col`}>
          <div className={`${styles["box_2"]} flex-col`}>
            <div className={`${styles["section_2"]} flex-row justify-between`}>
              <div className={`${styles["image-wrapper_1"]} flex-col`}>
                <img
                  className={`${styles["thumbnail_1"]}`}
                  src={require("./assets/img/logo.png")}
                />
              </div>
            </div>
            <div className={`${styles["section_3"]} flex-row`}>
              <div className={`${styles["section_4"]} flex-col`}>
                {/* <div className={`${styles["group_1"]} flex-col`} /> */}
                <img className={`${styles["img_size_w100"]}`} src={rawData?.productInfo?.picUrl} alt="" />
              </div>
            </div>

            <div className={`${styles["section_5"]} flex-col`}>
              <div className={`${styles["text-wrapper_1"]} flex-row`}>
                {routeList.map((item) => (
                  <span
                    onClick={() => {
                      if (item.key !== "home") {
                        window.open(`/aboutus/${userId}`, "_self");
                      } else {
                        setRoute(item.key);
                      }
                    }}
                    className={` ${
                      item.key === route ? styles["text_2"] : styles["text_3"]
                    }`}
                  >
                    {item.name}
                  </span>
                ))}
              </div>
              <img
                className={`${styles["image_1"]}`}
                src={require("./assets/img/SketchPng5c66b728b2d58f808a24795718001f3c2330120015c02fc18e7c8c98aec3cbf6.png")}
              />
              {/* <div className={`${styles["block_1"]} flex-col`} /> */}
              <div className={`${styles["block_2"]} flex-row justify-between`}>
                <div className={`${styles["section_6"]} flex-col`}>
                  <img className={`${styles["img_size_w66"]}`} src={rawData?.productInfo?.iconUrl} alt="" />
                  <div className={`${styles["group_2"]} flex-col`} />
                </div>
                <span className={`${styles["text_5"]}`}>
                  {rawData?.productInfo?.name}
                </span>
              </div>
              <div className={`${styles["block_3"]} flex-row justify-between`}>
                <div className={`${styles["text-wrapper_2"]}`}>
                  <span className={`${styles["text_6"]}`}>软件版本：</span>
                  <span className={`${styles["text_7"]}`}>
                    {rawData?.productInfo?.version}
                  </span>
                </div>
                <div className={`${styles["text-wrapper_3"]}`}>
                  <span className={`${styles["text_8"]}`}>软件大小：</span>
                  <span className={`${styles["text_9"]}`}>
                    {rawData?.productInfo?.size}
                  </span>
                </div>
              </div>
              <div className={`${styles["block_4"]} flex-row justify-between`}>
                <div className={`${styles["text-wrapper_4"]}`}>
                  <span className={`${styles["text_10"]}`}>更新时间：</span>
                  <span className={`${styles["text_11"]}`}>
                    {rawData?.productInfo?.updateTime}
                  </span>
                </div>
                <div className={`${styles["text-wrapper_5"]}`}>
                  <span className={`${styles["text_12"]}`}>应用平台：</span>
                  <span className={`${styles["text_13"]}`}>
                    {rawData?.productInfo?.platform}
                  </span>
                </div>
              </div>
              <div
                className={`${styles["block_5"]} flex-row`}
                onClick={() => handleDownload()}
              >
                <img
                  className={`${styles["label_1"]}`}
                  src={require("./assets/img/SketchPng664eac5afd38e1468b99e48d649320698c714d13dc0ddd09c349985c19ca454c.png")}
                />
                <span className={`${styles["text_14"]}`}>立即下载</span>
              </div>
              <span className={`${styles["text_15"]}`}>
                通过724软件平台，享高速下载等更多增值权益
              </span>
              <img
                className={`${styles["image_2"]}`}
                src={require("./assets/img/SketchPng251fc0195a2bcfe8081f6109ab32d3f7d7a1ee590f4d93de8175c9fcdf920429.png")}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles["section_7"]} flex-col`}>
        <div className={`${styles["group_3"]} flex-row`}>
          <div className={`${styles["box_3"]} flex-col`}>
            <div className={`${styles["box_4"]} flex-row justify-between`}>
              <div className={`${styles["group_4"]} flex-col`} />
              <span className={`${styles["text_16"]}`}>软件介绍</span>
            </div>
            <img
              className={`${styles["image_3"]}`}
              src={require("./assets/img/SketchPngb25e74445632bb804978aa61c0323ea20456854b9763113764e0482259e6e923.png")}
            />
            <p className={`${styles["text_17"]}`}>
              {rawData?.productInfo?.brief}
            </p>
          </div>
          {/* <img
            className={`${styles["thumbnail_2"]}`}
            src={require("./assets/img/SketchPng1b91af82a8199ade20cbcda704ff112bd3a06765bfa5123832cf44fd51c00b36.png")}
          />
          <div className={`${styles["box_5"]} flex-col`}>
            <div className={`${styles["group_5"]} flex-col`} />
          </div> */}
        </div>
        <div className={`${styles["group_6"]} flex-row justify-between`}>
          <div className={`${styles["group_7"]} flex-col`}>
            <div className={`${styles["section_8"]} flex-row justify-between`}>
              <div className={`${styles["group_8"]} flex-col`} />
              <span className={`${styles["text_18"]}`}>我们的优势</span>
            </div>
            <img
              className={`${styles["image_4"]}`}
              src={require("./assets/img/SketchPngb25e74445632bb804978aa61c0323ea20456854b9763113764e0482259e6e923.png")}
            />
            <div className={`${styles["section_9"]} flex-row`}>
              <div className={`${styles["box_6"]} flex-col justify-between`}>
                <div className={`${styles["block_6"]} flex-col`} />
                <span className={`${styles["text_19"]}`}>一键安装卸载</span>
              </div>
              <img
                className={`${styles["image_5"]}`}
                src={require("./assets/img/SketchPng77ae1221b56ef73de065fd0aa5b5cc73798e555e2c80672262c39d964eaf3eaa.png")}
              />
              <div className={`${styles["box_7"]} flex-col justify-between`}>
                <img
                  className={`${styles["image_6"]}`}
                  src={require("./assets/img/icon14.png")}
                />
                <span className={`${styles["text_20"]}`}>软件权限管理</span>
              </div>
              <img
                className={`${styles["image_7"]}`}
                src={require("./assets/img/SketchPng77ae1221b56ef73de065fd0aa5b5cc73798e555e2c80672262c39d964eaf3eaa.png")}
              />
              <div className={`${styles["box_8"]} flex-col justify-between`}>
                <div className={`${styles["section_10"]} flex-col`}></div>
                <span className={`${styles["text_21"]}`}>多维度的功能</span>
              </div>
              <img
                className={`${styles["image_8"]}`}
                src={require("./assets/img/SketchPng77ae1221b56ef73de065fd0aa5b5cc73798e555e2c80672262c39d964eaf3eaa.png")}
              />
              <div className={`${styles["box_9"]} flex-col justify-between`}>
                <div className={`${styles["box_10"]} flex-col`}></div>
                <span className={`${styles["text_22"]}`}>多版本的比较</span>
              </div>
            </div>
            {/* <div className={`${styles["section_11"]} flex-col`}>
              <div className={`${styles["block_8"]} flex-col`} />
            </div> */}
          </div>
          <div className={`${styles["group_10"]} flex-row`}>
            {rawData?.customerServiceInfo?.iconUrl && (
              <div
                onClick={() => {
                  window.open(rawData?.customerServiceInfo?.url, "_blank");
                }}
                className={`${styles["image-text_1"]} flex-col justify-between`}
              >
                <img
                  className={`${styles["label_2"]}`}
                  src={rawData?.customerServiceInfo?.iconUrl}
                />
                {/* <span className={`${styles["text-group_1"]}`}>联系客服</span> */}
              </div>
            )}
          </div>

          <div className={`${styles["group_101"]} flex-row`}>
            {rawData?.adInfo?.iconUrl && (
              <div
                onClick={() => {
                  window.open(rawData?.adInfo?.url, "_blank");
                }}
                className={`${styles["image-text_1"]} flex-col justify-between`}
              >
                <img
                  className={`${styles["label_2"]}`}
                  src={rawData?.adInfo?.iconUrl}
                />
              </div>
            )}
          </div>
        </div>
        <div className={`${styles["group_11"]} flex-col`}>
          <div className={`${styles["section_12"]} flex-row justify-between`}>
            <div className={`${styles["box_11"]} flex-col`} />
            <span className={`${styles["text_23"]}`}>安装步骤</span>
          </div>
          <img
            className={`${styles["image_9"]}`}
            src={require("./assets/img/SketchPngb25e74445632bb804978aa61c0323ea20456854b9763113764e0482259e6e923.png")}
          />
          <div className={`${styles["section_13"]} flex-row`}>
            {/* <div className={`${styles["group_12"]} flex-row`}>
              <div className={`${styles["image-text_2"]} flex-row`}>
                <div
                  className={`${styles["text-group_2"]} flex-row justify-between`}
                >
                  <span className={`${styles["text_24"]}`}>1</span>
                  <span className={`${styles["text_25"]}`}>购买软件</span>
                </div>
                <div className={`${styles["box_12"]} flex-col`}>
                  <div className={`${styles["group_13"]} flex-col`} />
                </div>
              </div>
              <div className={`${styles["block_9"]} flex-col`} />
            </div>
            <img
              className={`${styles["image_10"]}`}
              src={require("./assets/img/SketchPng433b01eeedd71e17ee772a8de5cde3962a1fec0a2c3d6e10436aedb1cef27ec1.png")}
            />
            <div className={`${styles["group_14"]} flex-row`}>
              <span className={`${styles["text_26"]}`}>2</span>
              <div className={`${styles["box_13"]} flex-col`} />
            </div>
            <div className={`${styles["group_15"]} flex-col justify-between`}>
              <span className={`${styles["text_27"]}`}>安装软件</span>
              <div className={`${styles["group_16"]} flex-col`} />
            </div>
            <img
              className={`${styles["image_11"]}`}
              src={require("./assets/img/SketchPng433b01eeedd71e17ee772a8de5cde3962a1fec0a2c3d6e10436aedb1cef27ec1.png")}
            />
            <div className={`${styles["group_17"]} flex-row`}>
              <span className={`${styles["text_28"]}`}>3</span>
              <div className={`${styles["box_14"]} flex-col`} />
            </div>
            <div className={`${styles["group_18"]} flex-col justify-between`}>
              <span className={`${styles["text_29"]}`}>启动软件</span>
              <div className={`${styles["section_14"]} flex-col`} />
            </div> */}
          </div>
          {/* <div className={`${styles["section_15"]} flex-col`} />
          <div className={`${styles["section_16"]} flex-col`} /> */}
        </div>
        <div className={`${styles["group_19"]} flex-row justify-between`}>
          <span className={`${styles["text_30"]}`}>
            以上都不需要，使用普通方式下载
          </span>
          <div
            className={`${styles["text-wrapper_6"]} flex-col`}
            onClick={() => handleDownload()}
          >
            <span className={`${styles["text_31"]}`}>立即下载</span>
          </div>
        </div>
        <div className={`${styles["group_20"]} flex-row`}>
          <span className={`${styles["paragraph_1"]}`}>
            Copyright©2023&nbsp;&nbsp;724down.com&nbsp;All&nbsp;rights&nbsp;reserved.&nbsp;&nbsp;&nbsp;
            <br />
            &nbsp;杭州赛游信息技术有限公司&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;浙公网安备&nbsp;33011002017281号&nbsp;|&nbsp;浙ICP备2023022389号
          </span>
          <img
            className={`${styles["thumbnail_3"]}`}
            src={require("./assets/img/SketchPnga761408ab4d30bbf7e46659f350742300832bd5eef6fb6921dae7707b34cb474.png")}
          />
        </div>
        {/* <div className={`${styles["group_21"]} flex-col`}>
          <div className={`${styles["box_15"]} flex-col`}>
            <div className={`${styles["section_17"]} flex-col`} />
          </div>
          <div className={`${styles["box_16"]} flex-col`}>
            <div className={`${styles["group_22"]} flex-col`} />
          </div>
        </div> */}
        {/* <div className={`${styles["group_23"]} flex-col`}>
          <div className={`${styles["block_10"]} flex-row`}>
            <span className={`${styles["text_32"]}`}>领取现金红包</span>
            <img
              className={`${styles["image_12"]}`}
              src={require("./assets/img/SketchPngc4f3ad483c74e9db2f03a7763c814e46ee71ef57dbcb24f369216443ddf97f60.png")}
            />
          </div>
        </div> */}
      </div>

      {visiable && (
        <div className={`${styles.outerLayer}`}>
          <div>
            {currentStep === 1 && (
              <Step1
                handleCloseDownload={handleCloseDownload}
                setCurrentStep={setCurrentStep}
                productDetailData={productDetailData}
                setUserData={setUserData}
                userData={userData}
              />
            )}
            {currentStep === 2 && (
              <Step2
                handleCloseDownload={handleCloseDownload}
                setCurrentStep={setCurrentStep}
                productDetailData={productDetailData}
                userData={userData}
                setPayResult={setPayResult}
              />
            )}
            {currentStep === 3 && (
              <Step3
                handleCloseDownload={handleCloseDownload}
                productDetailData={productDetailData}
                payResult={payResult}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default Home;
