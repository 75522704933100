"use strict";
import React, { useState, useEffect } from "react";
import { queryIndexInfo } from "../../services/api.js";

import styles from "./assets/index.module.css";
const routeList = [
  {
    name: "首页",
    key: "home",
  },
  {
    name: "关于我们",
    key: "about",
  },
  {
    name: "联系方式",
    key: "contact",
  },
];
const DDS_lanhupage_0 = ({ match }) => {
  const [route, setRoute] = useState("about");
  const { userId } = match.params;

  const getData = async () => {
    let res = await queryIndexInfo(userId);
    if (res.code === 0) {
      if (res.data?.siteInfo?.title) {
        document.title = res.data?.siteInfo?.title;
      }
      changeFavicon(res.data?.siteInfo?.iconUrl);
    }
  };
  function changeFavicon(src) {
    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = src;
  }
  useEffect(() => {
    getData();
  }, []);
  return (
    <div className={`${styles.page} flex-col justify-end`}>
      <div className={`${styles["box_1"]} flex-col`}>
        <div className={`${styles["text-wrapper_1"]} flex-row`}>
          <span className={`${styles["text_1"]}`}>724软件</span>
          <div className={`${styles["text-wrapper_tag"]} flex-row`}>
            {routeList.map((item) => (
              <span
                onClick={() => {
                  if (item.key === "home") {
                    window.open(`/${userId}`, "_self");
                  } else {
                    if (item.key === "contact") {
                      window.scrollTo(0, document.body.scrollHeight);
                    } else {
                      window.scrollTo(0, 0);
                    }
                    setRoute(item.key);
                  }
                }}
                className={`${
                  item.key === route ? styles["text_2"] : styles["text_3"]
                }`}
              >
                {item.name}
              </span>
            ))}
          </div>
        </div>
        <span className={`${styles["text_5"]}`}></span>
        <span className={`${styles["paragraph_1"]}`}>
          724软件是中国起步较早，口碑良好的精品软件、游戏下载网站，一直致力于为国内外用户提供安全、稳定、全面、高速的免费下载服务；网站紧跟时代潮流，不断结合用户的真实需求，提升网站的用户体验，努力将网站打造成集软件下载、正版软件分发、高质量游戏产生等多维一体的下载生态，让更多的用户可以快速准确便捷的找到自己的目标内容，满足用户的核心需求。
          <br />
          <br />
          724软件园作为老牌下载站长期致力于反盗版、反病毒、反流氓软件的行动，在行业和用户中树立了良好的口碑和知名度。
          <br />
          <br />
        </span>
        <div className={`${styles["block_2"]} flex-col`}>
          <div className={`${styles["image-wrapper_1"]} flex-col`}>
            <img
              className={`${styles["thumbnail_1"]}`}
              src={require("./assets/img/MasterDDSSlicePNG6ddd19d89d8563179dbb261ae474865e.png")}
            />
          </div>
        </div>
        <div className={`${styles["block_3"]} flex-col`}>
          {/* <span className={`${styles["text_6"]}`}>关于724软件</span>
          <div className={`${styles["group_1"]} flex-col`} /> */}
        </div>
      </div>
      <div className={`${styles["box_2"]} flex-col`}>
        <div className={`${styles["text-wrapper_2"]} flex-row`}>
          {/* <span className={`${styles["text_7"]}`}>Contact&nbsp;us</span>
          <span className={`${styles["text_8"]}`}>联系我们</span> */}
        </div>
        <div className={`${styles["group_2"]} flex-row justify-between`}>
          <div className={`${styles["group_3"]} flex-col`}>
            <div className={`${styles["group_4"]} flex-col`} />
          </div>
          <div className={`${styles["text-wrapper_3"]}`}>
            <span className={`${styles["text_9"]}`}>地址：</span>
            <span className={`${styles["paragraph_2"]}`}>
              杭州市余杭区创景路526号创客空间大厦2栋1408室
              <br />
            </span>
            <span className={`${styles["text_10"]}`}>官方邮件：</span>
            <span className={`${styles["paragraph_3"]}`}>
              724&#64;724down.com
              <br />
            </span>
            <span className={`${styles["text_11"]}`}>商务合作邮箱：</span>
            <span className={`${styles["paragraph_4"]}`}>
              &nbsp;business&#64;724down.com
              <br />
            </span>
            <span className={`${styles["text_12"]}`}>供应商合作邮箱：</span>
            <span className={`${styles["text_13"]}`}>
              &nbsp;pc&#64;724down.com
            </span>
          </div>
        </div>
        <div className={`${styles["group_5"]} flex-col`} />
        <div className={`${styles["group_6"]} flex-col`} />
      </div>
      <div className={`${styles["box_3"]} flex-col`}>
        <span className={`${styles["paragraph_5"]}`}>
          Copyright©2023&nbsp;&nbsp;724down.com&nbsp;All&nbsp;rights&nbsp;reserved.&nbsp;&nbsp;&nbsp;
          <br />
          &nbsp;杭州赛游信息技术有限公司&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;浙公网安备&nbsp;33011002017281号&nbsp;|&nbsp;浙ICP备2023022389号
        </span>
        <div className={`${styles["block_4"]} flex-col`}>
          <div className={`${styles["box_4"]} flex-col`} />
        </div>
      </div>
    </div>
  );
};
export default DDS_lanhupage_0;
