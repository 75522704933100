import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import { ConfigProvider } from "antd";
import "./App.css";
import Home from "./view/home/index.jsx";
import About from "./view/about/index.jsx";
// import About from "./view/about/index.jsx";
import zhCN from "antd/es/locale/zh_CN"; // 引入中文语言包
const App = () => {
  //数据大屏自适应函数
  const handleScreenAuto = () => {
    const designDraftWidth = 1920; //设计稿的宽度
    let scale = document.documentElement.clientWidth / designDraftWidth;
    if (document.documentElement.clientWidth < 1800) {
      scale = (document.documentElement.clientWidth / designDraftWidth) * 1.3;
    }
    document.querySelector("body").style.transform = `scale(${scale}) `;
  };

  //React的生命周期 如果你是vue可以放到mountd或created中
  useEffect(() => {
    //初始化自适应  ----在刚显示的时候就开始适配一次
    handleScreenAuto();
    //绑定自适应函数   ---防止浏览器栏变化后不再适配
    window.onresize = () => handleScreenAuto();
    //退出大屏后自适应消失   ---这是react的组件销毁生命周期，如果你是vue则写在deleted中。最好在退出大屏的时候接触自适应
    return () => (window.onresize = null);
  }, []);
  return (
    <ConfigProvider locale={zhCN}>
      <Router>
        <div>
          <Switch>
            <Route exact path="/:userId" component={Home} />
            <Route path="/aboutus/:userId" component={About} />
          </Switch>
        </div>
      </Router>
    </ConfigProvider>
  );
};

export default App;
