import qs from 'qs';
import request from '../commons/request';
let isMock = false;
export const getUrlParams = key => {
  // 获取当前页面的 URL
  const currentUrl = new URL(window.location.href);

  // 使用 URLSearchParams 获取查询参数
  const searchParams = new URLSearchParams(currentUrl.search);

  const value = searchParams.get(key);
  return value;
};

export function queryIndexInfo(code) {
  if (isMock) {
    return {
      code: 0,
      message: '成功',
      data: {
        productInfo: {
          code: '123456',
          name: 'Fences 4',
          iconUrl: 'https://syntheticbiology.itic-sci.com/static/media/gkxLogoCircle.c75ecce0f75c13477e38.svg',
          picUrl: 'https://syntheticbiology.itic-sci.com/static/media/home2.938f5349f42ad1a6b40a.png',
          version: '12.10.0',
          size: '120.0M',
          updateTime: '2023-12-10',
          platform: 'win10',
          brief: '软件介绍'
        },
        customerServiceInfo: {
          iconUrl: 'https://syntheticbiology.itic-sci.com/static/media/gkxLogoCircle.c75ecce0f75c13477e38.svg',
          url: 'https://www.icon.com/icon.html'
        },
        adInfo: {
          iconUrl: 'https://syntheticbiology.itic-sci.com/static/media/gkxLogoCircle.c75ecce0f75c13477e38.svg',
          url: 'https://www.icon.com/icon.html'
        },
        siteInfo: {
          title: '网站标题'
        }
      }
    };
  }

  return request({
    url: `/api/query_index_info`,
    method: 'post',
    data: {
      code
    }
  });
}

export function queryProductDetail(code) {
  if (isMock) {
    return {
      code: 0,
      message: '成功',
      data: {
        code: '123456',
        name: 'Fences 4',
        iconUrl: 'https://syntheticbiology.itic-sci.com/static/media/gkxLogoCircle.c75ecce0f75c13477e38.svg',
        picUrl: 'https://syntheticbiology.itic-sci.com/static/media/home2.938f5349f42ad1a6b40a.png',
        version: '12.10.0',
        size: '120.0M',
        updateTime: '2023-12-10',
        platform: 'win10',
        brief: '软件介绍',
        notieContent: '公告内容',
        noticeUrl: 'https://www.notice.com',
        sku: [
          {
            name: '规格',
            code: '123456',
            price: 12.0
          },
          {
            name: '规格1',
            code: '1234562',
            price: 112.0
          }
        ]
      }
    };
  }
  return request({
    url: `/api/query_product_detail`,
    method: 'post',
    data: {
      code
    }
  });
}
export function queryPayInfo(params) {
  if (isMock) {
    return {
      code: 0,
      message: '成功',
      data: {
        payUrl: 'https://www.pay.com',
        expireTimestamp: '1702737838',
        orderNo: '123456',
        expireMillisecond: '300000'
      }
    };
  }
  return request({
    url: `/api/query_pay_info`,
    method: 'post',
    data: params
  });
}

export function queryPayResult(orderNo) {
  if (isMock) {
    return {
      code: 0,
      message: '成功',
      data: {
        payStatus: 0,
        name: 'Fences 4',
        iconUrl: 'https://www.icon.com/icon.png',
        brief: '软件介绍',
        mainBtn: {
          text: '按钮文案',
          url: 'https://www.btn.com'
        },
        subBtn: {
          text: '按钮文案',
          url: 'https://www.btn.com'
        }
      }
    };
  }
  return request({
    url: `/api/query_pay_result`,
    method: 'post',
    data: {
      orderNo
    }
  });
}
